import React, { useContext, useEffect, useState } from 'react'
import { navigate } from 'gatsby'

// Components
import DilanButton from '../../../components/atoms/DilanButton'

// Context
import LocalizationContext from '../../../context/Localization/Localization.context'
// Utils
import { getFont } from '../../../utils/utils'

// constants
import { notFound } from '../../../config/localization/404'

// Style
import {
  wrapper,
  pageStyle,
  titleStyle,
  background,
  buttonStyle,
  numberStyle,
  pbCorrectionBut,
  descriptionStyle,
} from './styles.module.scss'

const NotFound = () => {
  const { language } = useContext(LocalizationContext)
  const [localization, setLocalization] = useState(notFound.Default)

  useEffect(() => {
    const newLocalization = notFound[language]
    if (typeof newLocalization !== 'undefined') {
      setLocalization(newLocalization)
    }
  }, [language])

  return (
    <>
      <div className={`block-wrapper ${background}`}>
        <div className={`container ${wrapper}`}>
          <h2 className={`${titleStyle} ${getFont('font-patua')}`}>
            {localization.sorry}
          </h2>
          <h3 className={`${descriptionStyle} ${getFont('font-lato')}`}>
            {localization.dilan}
          </h3>
          <p className={`${numberStyle} ${getFont('font-patua')}`}>404</p>
          <p className={`${pageStyle} ${getFont('font-lato')}`}>
            {localization.page}
          </p>
          <DilanButton
            type={'primary'}
            className={`${buttonStyle} ${getFont(
              'font-lato',
              pbCorrectionBut
            )}`}
            handleClick={() => {
              navigate('/')
            }}
          >
            {localization.home}
          </DilanButton>
        </div>
      </div>
    </>
  )
}

export default NotFound
