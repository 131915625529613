const notFound = {
    Default: {
      sorry: 'sorry_msg',
      dilan: 'dillan_msg',
      page: 'not_found',
      home: 'home_but',
    },
    ENG: {
        sorry: "We're sorry...",
        dilan: 'It looks like Dilan broke something... again',
        page: 'Page not found',
        home: 'Go Home',
    },
    ESP: {
        sorry: 'Lo sentimos...',
        dilan: 'Parece que Dilan a roto algo... de nuevo',
        page: 'Página no encontrada',
        home: 'Ir al Home',
    },
    ADN: {
        sorry: 'sorry_msg',
        dilan: 'dillan_msg',
        page: 'not_found',
        home: 'home_but',
    },
  }
  
  export { notFound }
  