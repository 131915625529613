import React from 'react'
import NotFound from '../components/templates/NotFound'
import PageTemplate from '../components/templates/PageTemplate'

const NotFoundPage = () => {
  return (
    <PageTemplate>
      <NotFound />
    </PageTemplate>
  )
}

export default NotFoundPage
